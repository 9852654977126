<template>
  <div class="container">
    <div class="hh_classify_wrap">
      <div class="hh_sub common_inner">
        <div class="hh_classify">
          <span
            class="hh_classify_item"
            :class="{ hh_classify_active: allStatusOne === 1 }"
            @click="clickCourseLabelMenu('oneAll')"
            >全部</span
          >
          <span
            class="hh_classify_item"
            :class="{
              hh_classify_active: item.newsType == clickCourseLabelStatus,
            }"
            v-for="item in subjectList"
            :key="item.newsType"
            @click="clickCourseLabelMenu('oneItem', item)"
          >
            {{ item.newsType }}
          </span>
        </div>
      </div>
    </div>
    <consult-com :consultData="consultData"></consult-com>
    <none-data
      v-if="consultData.length <= 0"
      :searchType="searchType"
    ></none-data>
    <pagination
      class="pagination jumpBtn"
      v-if="totalCount >= 1"
      :pageSize="formData.pageSize"
      :total="totalCount"
      @pageChange="pageChange"
      @sizeChange="sizeChange"
    />
  </div>
</template>
<script>
import dictionaryAPI from "@/apis/backStageEndAPI/dictionaryManagementAPI";
import CommonUtils from "@/utils";
import API from "@/apis/backStageEndAPI/consultManageAPI";
import { EventBus } from "../../../assets/js/eventBus";
import noneData from "@comps/backStageComponent/noneData";
import Pagination from "@comps/backStageComponent/pagination";
import consultCom from "./components/consultCom";
export default {
  name: "consultList",
  components: { consultCom, noneData, Pagination },
  data() {
    return {
      clickCourseLabelStatus: "",
      subjectList: [],
      allStatusOne: 1,
      searchType: 1,
      totalCount: 0,
      consultData: [],
      formData: {
        page: 1,
        pageSize: 12,
        newsType: "",
        newsTitle: '',
      },
    };
  },
  watch: {
    '$store.state.student.value': {
      handler(val) {
        if (val == '') {
          this.formData.newsTitle = ''
          this.getConsultList()
        }
      },
      deep: true,
    },
  },
  mounted() {
    if(this.$store.state.student.value) {
      this.formData.newsTitle = this.$store.state.student.value
      this.getConsultList()
    }
    EventBus.$emit("curIndex", 4);
    if (sessionStorage.getItem("consultDataList")) {
      let data = JSON.parse(sessionStorage.getItem("consultDataList"));
      if (data.dataList.length > 0) {
        this.totalCount = data.rowCount;
        this.consultData = data.dataList;
      }
    } else if (sessionStorage.getItem("nodata")) {
      this.dibbledata = [];
    } else {
      this.getConsultList();
    }
    this.getCourseLabel()
  },
  beforeDestroy() {
    sessionStorage.removeItem("secondList");
    sessionStorage.removeItem("consultDataList");
    sessionStorage.removeItem("nodata");
  },
  methods: {
    getCourseLabel() {
      //资讯分类
      dictionaryAPI.getDictionaryData("consult_code").then((res) => {
        this.subjectList = res;
      });
    },
    getConsultList() {
      API.studyConsultList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          this.consultData = res.dataList || [];
          this.totalCount = res.rowCount || 0;
        }
      );
    },
    clickCourseLabelMenu(type, data) {
      if (type == "oneAll") {
        this.allStatusOne = 1;
        this.clickCourseLabelStatus = "";
        this.formData.newsType = "";
        this.getConsultList();
      } else {
        this.allStatusOne = "";
        this.clickCourseLabelStatus = data.newsType;
        this.formData.newsType = data.newsType;
        this.getConsultList();
      }
    },
    pageChange(val) {
      if (val) {
        this.formData.page = val;
        this.getConsultList();
      }
    },
    sizeChange(val) {
      if (val) {
        this.formData.page = val;
        this.getConsultList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/common.scss";
$color: var(--colorBlue);
.container {
  min-height: calc(100vh - 303px);
  width: 100%;
  background: #f7faff;
  .hh_classify_wrap {
    width: 100%;
    padding-top: 10px;
    .hh_sub {
      display: flex;
      .gl_sub_t {
        padding-top: 5px;
        width: 76px;
        white-space: nowrap;
      }
      .hh_classify {
        flex: 1;
        .hh_classify_item {
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 6px 11px;
          border-radius: 7px;
          color: #333;
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
        }
        .hh_classify_active {
          background: rgba(212, 229, 255, 1);
          color: $color;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
